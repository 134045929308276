import ReactDOM from "react-dom";
import { useState, useEffect, useRef } from "react";

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const Portals = () => {
  const [firstLocalstorageTimeStamp, setFirstLocalstorageTimeStamp] = useState(null);
  const prevCount = usePrevious(firstLocalstorageTimeStamp);

  useEffect(() => {
  
    const localStorageTimeStamp = window.localStorage.getItem("login_timeStamp");
    setFirstLocalstorageTimeStamp(localStorageTimeStamp);

    window.onstorage = (event) => {
 
      const localStorageTimeStamp = window.localStorage.getItem("login_timeStamp");
      setFirstLocalstorageTimeStamp(localStorageTimeStamp);
    };
  }, []);

  useEffect(() => {
    if (prevCount) {
      if (prevCount != firstLocalstorageTimeStamp) {
        setFirstLocalstorageTimeStamp(null);
      

        const localStorageTimeStamp = window.localStorage.getItem("login_timeStamp");
        const sessionStorageTimeStamp = window.sessionStorage.getItem("login_timeStamp");

        if (localStorageTimeStamp != sessionStorageTimeStamp) {
          if(sessionStorageTimeStamp) {
            window.close();
          } else {
            setTimeout(() => {
              window.location.reload(true);
            }, 1000)
          
          }
      
      }
      }
    }
  }, [firstLocalstorageTimeStamp, prevCount]);

  return ReactDOM.createPortal(
    <></>,
    document.getElementById("portal")
  );
};

export default Portals;