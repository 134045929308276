import {useState, useEffect} from 'react'
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';
import { interviewCreatedApi } from '../../../services/Integrations';
import {interviewCreatedApiGreenHouse, interviewCreatedApiWorkable} from '../../../services/Integrations';

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LOGO from "@iv/shared/assets/images/iv-logo.svg"
import calender from "../../../assets/images/calender.svg";
import DefaultLoader from '../../atoms/Loader/DefaultLoader';

import "./interviewCreated.scss"


const InterviewCreated = () => {
  const location = useLocation();
  const search = useLocation().search;
  const token = new URLSearchParams(search).get('token');
  const greenHouse = location?.pathname?.includes('greenhouse') ? true : false;
  const workable = location?.pathname?.includes('workable') ? true : false;
  const history = useHistory();
  const [data, setData] = useState({});
  const [loader, setLoader] = useState(true);

  const fetchData = async ()=>{
    const headers = {
      "X-Client-Request-Access-Token":token,
    }
    let interviewCreate =  greenHouse ? await interviewCreatedApiGreenHouse(headers)
     : workable ? await interviewCreatedApiWorkable(headers) : await interviewCreatedApi(headers);
    if (interviewCreate.status === 200){
      setData(interviewCreate?.data?.data);
      setLoader(false);
    }
    else{
      history.push(`/create-round-interview?token=${token}`);
    }
  }

  useEffect(()=>{
    fetchData();
  },[])

const Navbar = () => {
    return(
        <Box
        display= "flex"
        className="navbar-wrapper"
        justifyContent="flex-start"
        alignItems="center"
        >
           
            <Box sx={{ml:"100px", mt:"6px"}}>
              <img  src={LOGO} alt='company-logo' className='logoImg'/>
            </Box>
            
            <Box className='center-text'>
            <Typography className="heading-text">
            INTERVIEW CREATED
        </Typography>
            </Box>
       
        </Box>
    )
}

const Body = () => {
return(
  <Box 
  display="flex"
  justifyContent="start"
  >
     <Box sx={{mt: "80px"}}>
    < img src={calender}  alt="calender-img"/>
  </Box>

  <Box sx={{mt:"150px", ml: "200px"}}>
    <Box>
    <Typography className='interview-heading'>Interview Created!</Typography>
    </Box>
    <Box sx={{mt:"100px"}} className='interview-body'>
      <Typography sx={{ml:"140px"}} className="interview-text">
        <span className='interview-text-span'>

          {
          data?.round_name === undefined ? '---' : data?.round_name
          } 
          </span> has been 
      </Typography>
      <Typography sx={{ml:"-90px"}}className="interview-text">
      created for <span className='interview-text-span'> 
      {
        data?.candidate_name === undefined ? '---' : data?.candidate_name
      }
       </span> for the position <span className='interview-text-span'>
         {
           data?.position_name === undefined ? '---' : data?.position_name
         }
        .</span> 
      </Typography>
    </Box>
    <Box className='button'>
      <button onClick={()=>window.close()}>CLOSE TAB</button>
    </Box>
   
  </Box>
  </Box>
 
)
}

  return (
    <>
   <Box className="interview-created-main-wrapper">
     <Box>
        {Navbar()}
      </Box> 
      { loader === true ? <DefaultLoader isLoading={loader} /> : 
        <Box className="interview-body-box">
          {Body()} 
        </Box>
      }
    </Box>
    </>
  )
}

export default InterviewCreated
