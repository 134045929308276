export const FormAction = {
    ADD: "ADD",
    EDIT: "EDIT",
    DUPLICATE : "DUPLICATE"
};

export const StepperSteps = {
    TEST_CONFIGURATIONS: { key: 'TEST_CONFIGURATIONS', label: 'Test Configurations' },
    QUESTION_SET: { key: 'QUESTION_SET', label: 'Question Set' },
}

export const StagesType = {
    TEST_CONFIGURATIONS : "TEST_CONFIGURATIONS",
    QUESTION_SET : "QUESTION_SET",
}

export const PRESCREENING_TYPE = {
    TEST_VALIDITY : "testValidity",
    TEST_START_TIMESTAMP : "testStartTimestamp",
}

export const PRESCREENING_STATUS = {
    APPROVED : "APPROVED",
    INACTIVE : "INACTIVE",
}

export const PRESCREEN_TOPIC = {
    OTHERS : "OTHERS_QUESTION_LIST",
}

export const popUpStyles = {
     headingStyle : {
        fontWeight: '600',
        fontFamily: 'Poppins',
        fontSize: '18px',
        color: '#3D3D3D'
     },

     contentBox: {
        width: '460px',
        paddingRight: '15px',
        paddingLeft: '15px' 
     },

     contentStyles: {
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontWeight: '400',
        fontColor: '#3D3D3D' ,
        marginTop: '10px'
      },

      subTextStyles: {
        fontFamily: 'Poppins',
        fontSize: '12px',
        fontWeight: '400',
        fontColor: '#696969',
        marginTop: '10px'
      },

      buttonBox: {
         display: 'flex',
         flexDirection: 'row',
         justifyContent: 'flex-end',
         marginTop: '30px'
      },
      confirmBtnBtn: {
         backgroundColor: '#981313',
         color: '#FFFFFF',
         fontFamily: 'Poppins',
         fontSize: '14px',
         fontWeight: '600',
         height: '40px',
         width: '101px',
         borderRadius: '11px',
         border: 'none'
      },

      cancelBtn: {
          backgroundColor: '#FFFFFF',
          fontFamily: 'Poppins',
         fontSize: '14px',
         fontWeight: '600',
         height: '40px',
         width: '101px',
         borderRadius: '11px',
         border:  '1px solid #696969',
         marginRight: '10px'
      }
    }
export const OPTIONS = {
    OPTION_ONE: 'OPTION_ONE',
    OPTION_TWO: 'OPTION_TWO',
    OPTION_THREE: 'OPTION_THREE',
    OPTION_FOUR: 'OPTION_FOUR',
    OPTION_FIVE: 'OPTION_FIVE',
    OPTION_SIX: 'OPTION_SIX',
    OPTION_SEVEN: 'OPTION_SEVEN',
    OPTION_EIGHT: 'OPTION_EIGHT',
    OPTION_NINE: 'OPTION_NINE',
    OPTION_TEN: 'OPTION_TEN'
}

export const OPTIONS_LABEL = {
    option1: 'OPTION_ONE',
    option2: 'OPTION_TWO',
    option3: 'OPTION_THREE',
    option4: 'OPTION_FOUR',
    option5: 'OPTION_FIVE',
    option6: 'OPTION_SIX',
    option7: 'OPTION_SEVEN',
    option8: 'OPTION_EIGHT',
    option9: 'OPTION_NINE',
    option10: 'OPTION_TEN'
}

export const INPUT_LABEL = {
    OPTION_ONE: 'option1',
    OPTION_TWO: 'option2',
    OPTION_THREE: 'option3',
    OPTION_FOUR: 'option4',
    OPTION_FIVE: 'option5',
    OPTION_SIX: 'option6',
    OPTION_SEVEN: 'option7',
    OPTION_EIGHT: 'option8',
    OPTION_NINE: 'option9',
    OPTION_TEN: 'option10',
}

export const getOptionLabel = {
    OPTION_ONE: {inputLabel: 'option1', label: 'OPTION_ONE'},
    OPTION_TWO: {inputLabel: 'option2', label: 'OPTION_TWO'},
    OPTION_THREE: {inputLabel: 'option2', label: 'OPTION_THREE'},
    OPTION_FOUR: {inputLabel: 'option4', label: 'OPTION_FOUR'},
    OPTION_FIVE: {inputLabel: 'option5', label: 'OPTION_FIVE'},
    OPTION_SIX: {inputLabel: 'option6', label: 'OPTION_SIX'},
    OPTION_SEVEN: {inputLabel: 'option7', label: 'OPTION_SEVEN'},
    OPTION_EIGHT: {inputLabel: 'option8', label: 'OPTION_EIGHT'},
    OPTION_NINE: {inputLabel: 'option9', label: 'OPTION_NINE'},
    OPTION_TEN: {inputLabel: 'option10', label: 'OPTION_TEN'},
}

export const getOptionLabelByInputLabel = (inputLabel) => {
    let question_response = ''
    if (inputLabel == INPUT_LABEL.OPTION_ONE){
        question_response = OPTIONS.OPTION_ONE
    } else if (inputLabel == INPUT_LABEL.OPTION_TWO) {
        question_response = OPTIONS.OPTION_TWO
    } else if (inputLabel == INPUT_LABEL.OPTION_THREE) {
        question_response = OPTIONS.OPTION_THREE
    } else if (inputLabel == INPUT_LABEL.OPTION_FOUR) {
        question_response = OPTIONS.OPTION_FOUR
    } else if (inputLabel == INPUT_LABEL.OPTION_FIVE) {
        question_response = OPTIONS.OPTION_FIVE
    } else if (inputLabel == INPUT_LABEL.OPTION_SIX) {
        question_response = OPTIONS.OPTION_SIX
    } else if (inputLabel == INPUT_LABEL.OPTION_SEVEN) {
        question_response = OPTIONS.OPTION_SEVEN
    } else if (inputLabel == INPUT_LABEL.OPTION_EIGHT) {
        question_response = OPTIONS.OPTION_EIGHT
    } else if (inputLabel == INPUT_LABEL.OPTION_NINE) {
        question_response = OPTIONS.OPTION_NINE
    } else if (inputLabel == INPUT_LABEL.OPTION_TEN) {
        question_response = OPTIONS.OPTION_TEN
    }
   return question_response;
}

export const getInputLabelByOptionLabel = (optionLabel) => {
    let question_response = ''
    if (optionLabel == OPTIONS.OPTION_ONE){
        question_response = INPUT_LABEL.OPTION_ONE
    } else if (optionLabel == OPTIONS.OPTION_TWO) {
        question_response = INPUT_LABEL.OPTION_TWO
    } else if (optionLabel == OPTIONS.OPTION_THREE) {
        question_response = INPUT_LABEL.OPTION_THREE
    } else if (optionLabel == OPTIONS.OPTION_FOUR) {
        question_response = INPUT_LABEL.OPTION_FOUR
    } else if (optionLabel == OPTIONS.OPTION_FIVE) {
        question_response = INPUT_LABEL.OPTION_FIVE
    } else if (optionLabel == OPTIONS.OPTION_SIX) {
        question_response = INPUT_LABEL.OPTION_SIX
    } else if (optionLabel == OPTIONS.OPTION_SEVEN) {
        question_response = INPUT_LABEL.OPTION_SEVEN
    } else if (optionLabel == OPTIONS.OPTION_EIGHT) {
        question_response = INPUT_LABEL.OPTION_EIGHT
    } else if (optionLabel == OPTIONS.OPTION_NINE) {
        question_response = INPUT_LABEL.OPTION_NINE
    } else if (optionLabel == OPTIONS.OPTION_TEN) {
        question_response = INPUT_LABEL.OPTION_TEN
    }
   return question_response;
}


export const PRESCREEN_QUESTION_TYPE = {
    MCQ_OUTPUT_BASED: 'MCQ_OUTPUT_BASED',
    MCQ_CONCEPTUAL: 'MCQ_CONCEPTUAL',
    MCQ_LOGICAL_PUZZLES: 'MCQ_LOGICAL_PUZZLES',
    MCQ_SITUATIONAL: 'MCQ_SITUATIONAL',
    MCQ_METRIC: 'MCQ_METRIC',
    MCQ_STRATEGIC: 'MCQ_STRATEGIC',
    MSQ_OUTPUT_BASED: 'MSQ_OUTPUT_BASED',
    MSQ_CONCEPTUAL: 'MSQ_CONCEPTUAL',
    MSQ_LOGICAL_PUZZLES: 'MSQ_LOGICAL_PUZZLES',
    MSQ_SITUATIONAL: 'MSQ_SITUATIONAL',
    MSQ_METRIC: 'MSQ_METRIC',
    MSQ_STRATEGIC: 'MSQ_STRATEGIC',
    INT_OUTPUT_BASED: 'INT_OUTPUT_BASED',
}

export const isMCQQuestionType = (questionType) => {
    if(questionType === PRESCREEN_QUESTION_TYPE.MCQ_OUTPUT_BASED 
        || questionType === PRESCREEN_QUESTION_TYPE.MCQ_CONCEPTUAL
        || questionType === PRESCREEN_QUESTION_TYPE.MCQ_LOGICAL_PUZZLES
        || questionType === PRESCREEN_QUESTION_TYPE.MCQ_SITUATIONAL
        || questionType === PRESCREEN_QUESTION_TYPE.MCQ_METRIC
        || questionType === PRESCREEN_QUESTION_TYPE.MCQ_STRATEGIC){
            return true
        }
        return false
}

export const isMSQQuestionType = (questionType) => {
    if(questionType === PRESCREEN_QUESTION_TYPE.MSQ_OUTPUT_BASED 
        || questionType === PRESCREEN_QUESTION_TYPE.MSQ_CONCEPTUAL
        || questionType === PRESCREEN_QUESTION_TYPE.MSQ_LOGICAL_PUZZLES
        || questionType === PRESCREEN_QUESTION_TYPE.MSQ_SITUATIONAL
        || questionType === PRESCREEN_QUESTION_TYPE.MSQ_METRIC
        || questionType === PRESCREEN_QUESTION_TYPE.MSQ_STRATEGIC){
            return true
        }
        return false
}

export const isIntQuestionType = (questionType) => {
    if(questionType === PRESCREEN_QUESTION_TYPE.INT_OUTPUT_BASED){
            return true
        }
        return false
}