import axios from "./axios";

const getValidToken = (type,token) => {
  let session_token = sessionStorage.getItem("temporary_access_token");
  let header_token = axios.defaults.headers.common['Authorization'];
  let finalToken = {}
  if(!session_token && !header_token)
    return;
  else if(session_token && header_token || session_token)
  {
    if(type === 'guide-comments')
    {
      finalToken["X-Guide-Access-Token"] = token?token:session_token;
    }
    else 
    {
      finalToken["X-Client-Request-Access-Token"] = session_token;
    }

    finalToken["Authorization"]= null;
  }
  else
  {
    finalToken["Authorization"] = header_token;
  }
  return finalToken;
}

export const getGuidesListAPI = async (client_id,payload) => {
  return axios
    .get(`/clients/${client_id}/guides`,{
      params:payload
    })
    .then((res) =>{
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

export const sendGuideApprovalAPI = (payload, guideID) => {
  return axios.post(`/guides/${guideID}/send_guide_for_approval`,payload).then(res=>{
      return res;
  }).catch(error=>{
      throw error?.response || error;
  })
}

export const guideApprovalAPI  = (payload, guideId, token) => {
  return axios.post(`/guides/${guideId}/status_change`, payload,
  {
    headers: {
      'X-Client-Request-Access-Token': token
    }
  }).then(res => {
    return res;
  }).catch(error => {
    throw error?.response || error;;
  })
}


export const getGuideTemplateAPI = (client_id,payload) => {
    return axios.post(`/clients/${client_id}/guides/template`,payload).then(res=>{
      return res;
    }).catch(error=>{
      return error?.response;
    })
}

export const getTemporaryGuideAccessToken = () => {
  const accessToken = sessionStorage.getItem('temporary_access_token');
  if (accessToken) {
    return {
      'X-Client-Request-Access-Token': accessToken
    };
  }
  return {};
};

export const getGuidePreviewDataAPI = (guide_id, params)=>{
    const tokenObj = getValidToken();
    if(!tokenObj)
      return new Promise((resolve,reject)=>reject({response:'Unauthorized access'}))

    let guideUrl = `/guides/${guide_id}`;
    return axios.get(guideUrl , {
      params,
      headers:{...tokenObj}
    }
    ).then(res=>{
      return res;
    }).catch(error=>{
      return error?.response;
  })
}

export const saveNewGuideAPI = (client_id,payload)=>{
  return axios.post(`/clients/${client_id}/guides`,payload).then(res=>{
    return res;
  }).catch(error=>{
    return error?.response;
  })
}

export const getEditGuideAPI = (guide_id)=>{
    return axios.get(`/guides/${guide_id}`).then(res=>{
      return res;
    }).catch(error=>{
      return error?.response;
    })
}

export const saveEditGuideAPI = (client_id,guide_id,payload)=>{
  return axios.put(`/clients/${client_id}/guides/${guide_id}`,payload).then(res=>{
    return res;
  }).catch(error=>{
    return error?.response;
  })
}


export const getQuestionBankAPI = ()=>{
  return axios.post(`/question_bank/get_questions`,{}).then(res=>{
      return res;
  }).catch(error=>{
    return error?.response;
  })
}

export const getAllowedtagsAPI = (payload) => {
  return axios
    .post(`/question_bank/filters`,payload)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error?.response;
    });
};

export const getQuestionByFiltersAPI = (payload) => {
  return axios
    .post(`/question_bank/get_questions`, payload)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error?.response;
    });
};

export const getGuideShareableLinkAPI = (guide_id)=>{
  return axios.get(`/guides/${guide_id}/share`).then((res)=>{
      return res;
    }).catch((error)=>{
    return error?.response;
  })
}

export const verifyGuideAccess = async (token) => {
  try {
      const result = await axios.get('/guides/verify_client_access', {
          headers: {
              'X-Client-Request-Access-Token': token,
              'Authorization': null
          }
      });
      return result.data;
  } catch (err){
      throw err;
  }
};

/*          Interview Guide Comments          */

// Get all comments in a guide
export const getAllGuideCommentsAPI = (payload, token) => {
  const tokenObj = getValidToken('guide-comments',token)
  if(!tokenObj)
    return new Promise((resolve,reject)=>reject({response:'Unauthorized access'}))

    return axios.post(`/guide-comment/all`,payload,{
      headers:tokenObj
    }).then(response=>{
      return response;
    }).catch(error=>{
      throw error?.response;
  });
}

//  Add Comments to a particular section in guide
export const addCommentAPI = (payload,token) => {
  const tokenObj = getValidToken('guide-comments',token)
  if(!tokenObj)
    return new Promise((resolve,reject)=>reject({response:'Unauthorized access'}))

  return axios.post(`/guide-comment/add`,payload,{
    headers:tokenObj
  }).then(response=>{
    return response;
  }).catch(error=>{
    throw error?.response;
  });
}

// Edit status of a comment
export const editCommentStatusAPI = (payload,token) => {
  const tokenObj = getValidToken('guide-comments',token)
  if(!tokenObj)
    return new Promise((resolve,reject)=>reject({response:'Unauthorized access'}))

  return axios.post(`/guide-comment/edit-status`,payload,{
    headers:tokenObj,
  }).then(response => {
    return response;
  }).catch(error => {
    throw error?.response;
  });
}

//  Add thread to a comment
export const addThreadAPI = (payload,token) => {
  const tokenObj = getValidToken('guide-comments',token)
  if(!tokenObj)
    return new Promise((resolve,reject)=>reject({response:'Unauthorized access'}))

    return axios.post(`/guide-comment/add-thread`,payload,{
      headers:tokenObj,
    }).then(response=>{
        return response;
    }).catch(error=>{
      throw error?.response;
  });
}

//  Delete a thread
export const deleteThreadAPI = (payload,token) => {
  const tokenObj = getValidToken('guide-comments',token)
  if(!tokenObj)
    return new Promise((resolve,reject)=>reject({response:'Unauthorized access'}))

  return axios.post(`/guide-comment/delete-thread`,payload,{
    headers:tokenObj,
  }).then(response => {
    return response;
  }).catch(error => {
    throw error?.response;
  });
}

export const getGuideTagsListAPI = (payload = {}) => {
  return axios
    .post(`/question_bank_tag/get_tags`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error?.response;
    });
};