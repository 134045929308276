import cogoToast from "cogo-toast";
import {imageFile} from "../services/file";

export const loadImage = (imageUrl, cb) => {
  if (!imageUrl) {
    return cb && cb('skipped');
  }

  const load = async () => {
    try{
      const image = await imageFile(imageUrl);
      const file = new File([new Blob([image.data])], "name");
  
      const reader = new FileReader();
      if (file) {
        reader.readAsDataURL(file);
        setTimeout(() => {
          cb && cb(null, reader.result);
        }, 200);
      }
    } catch(error) {
        handleError(error)
    }
   
  };

 const handleError = (error) => {
  const errorBlob = error?.response?.data;

  // Create a FileReader to read the text content from the Blob
  const reader = new FileReader();
  
  reader.onload = function () {
    // This will contain the text content of the Blob
    const errorText = reader.result;
    
    // Log or display the error text
    // console.log("Error Text:", errorText);
    return cogoToast.error('Image loading error: ' + errorText);
  };
  
  // Read the Blob as text
  reader.readAsText(errorBlob);
 }

  load();
};
