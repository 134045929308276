import axios from "./axios";
import Axios from "axios";

const getValidToken = () => {
  let session_token = sessionStorage.getItem("temporary_access_token");
  let header_token = axios.defaults.headers.common['Authorization'];
  let finalToken = {}
  if(session_token && header_token || session_token)
  {
    finalToken["X-Client-Request-Access-Token"] = session_token;
    finalToken["Authorization"]= null;
  }
  else
  {
    finalToken["Authorization"] = header_token;
  }
  return finalToken;
}


export const getAllPrescreening = (payload) => {
  return axios.post(`/pre_screening/get`,payload)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};

export const getPrescreeningByPrescreeningId = (payload, prescreeningId) => {
  return axios.get(`/pre_screening/${prescreeningId}`,{
    headers:{...getValidToken()}
  },payload)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};

export const getPrescreeningDefaultMarking = () => {
  return axios.get(`/pre_screening/get_default_question_marking`)
    .then((response) => response)
    .catch((err) => {
      return err?.response;
    });
};

export const createPreScreening = (payload) => {
  return axios.post(`/pre_screening/create`,payload)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};

export const editPreScreening = (payload) => {
  return axios.post(`/pre_screening/edit`,payload)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
}

export const editPreScreeningStatus = (payload) => {
  return axios.post(`/pre_screening/edit_status`,payload)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
}

export const sendPreScreeningReminder = (payload) => {
  return axios.post(`candidate_pre_screening_round/re-send-mail`,payload)
    .then((response) => response)
    .catch((err) => {
       throw err?.response;
    });
}

export const get_prescreen_rightDrawer = (payload={}) => {
  return axios.post(`candidate_pre_screening_round/candidate`,payload)
    .then((response)=>{
      return response; 
    })
    .catch((err)=>{
      return err.response;
    })
} 


export const fetchPrescreening = (payload) => {
  return axios.post(`/candidate_pre_screening_round/all`,payload)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
}


export const getPreScreening = (position_id,payload={}) => {
  return axios.post(`/position_pre_screening/${position_id}`,payload)
    .then((response)=>{
      return response; 
    })
    .catch((err)=>{
      return err.response;
    })
} 


export const getPreScreeningDetails = (prescreenId) => {
  return axios.get(`/pre_screening/${prescreenId}`)
    .then((response)=>{
      return response; 
    })
    .catch((err)=>{
      return err.response;
    })
} 

export const prescreeningTest_login = (jwtToken) => {
  const headers = {
    'X-Candidate-Request-Access-Token': jwtToken,
  }
  return axios.get("/candidate_pre_screening_round/info", {
    headers: headers
  })
    .then((response)=>{
      return response; 
    })
    .catch((err)=>{
      return err.response;
    })
} 

export const resendTest = (jwtToken, payload) => {
  const headers = {
    'X-Candidate-Request-Access-Token': jwtToken,
  }
  return axios.post(`candidate_pre_screening_round/re-send-mail`, payload, {
    headers: headers
  })
    .then((response) =>{
    return response
  })
    .catch((err) => {
       return err?.response;
    });
}

export const getPrescreenReportQuestions= (payload, candidate_pre_screening_round_id) => {
  return axios.post(`/candidate_pre_screening_round/get_asked_questions/${candidate_pre_screening_round_id}`,payload)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
}

export const prescreeningTest_submit = (payload, jwtToken) => {
  const headers = {
    'X-Candidate-Request-Access-Token': jwtToken,
  }
  return axios.post("/candidate_pre_screening_round/submit_candidate_response", payload, {
    headers: headers
  })
    .then((response)=>{
      return response; 
    })
    .catch((err)=>{
      return err.response;
    })
} 



export const send_otp = (jwtToken) => {
  const headers = {
    'X-Candidate-Request-Access-Token': jwtToken,
  }
  return axios.get("/candidate_pre_screening_round/send-otp", {
    headers: headers
  })
    .then((response)=>{
      return response; 
    })
    .catch((err)=>{
      return err.response;
    })
} 


export const validate_otp = (payload, jwtToken) => {
  const headers = {
    'X-Candidate-Request-Access-Token': jwtToken,
  }
  return axios.post(`/candidate_pre_screening_round/verify-otp`,payload, {
    headers: headers
  })
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
}

export const prescreenTestEdit = (candidate_pre_screening_round_id, payload) => {
  return axios.post(`/candidate_pre_screening_round/edit/${candidate_pre_screening_round_id}`,payload)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
}

export const prescreeningStartTest = (jwtToken) => {
  const headers = {
    'X-Candidate-Request-Access-Token': jwtToken,
  }
  return axios.get("candidate_pre_screening_round/can_start_test", {
    headers: headers
  })
    .then((response)=>{
      return response; 
    })
    .catch((err)=>{
      return err?.response;
    })
} 
export const getFilterOptionsAPI = () => {
  return axios.get(`/pre_screening_question_bank/filters`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error?.response;
    });
};

export const getQuestionBankListAPI = (payload = {}) => {
  return axios.post(`/pre_screening_question_bank/get_pre_screening_questions`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error?.response;
    });
};

export const getShareableLinkAPI = (id)=>{
  return axios.get(`/pre_screening/${id}/share`).then((res)=>{
      return res;
    }).catch((error)=>{
    return error?.response;
  })
}

export const getPrescreenReportData = (client_id, prescreen_round_id, jwtToken) => {
  const headers = jwtToken ? {
    'X-Client-Request-Access-Token': jwtToken,
  } : {};
  return axios.get(`/candidate_screening/${client_id}/${prescreen_round_id}/report`,{
    headers: headers
  })
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
}

export const verifyReportAccess = (jwtToken) => {
  const headers = {
    'X-Client-Request-Access-Token': jwtToken,
    'Authorization': null,
  }
  return axios.get("candidate_screening_round/verify_access", {
    headers: headers
  })
    .then((response)=>{
      return response?.data?.data; 
    })
    .catch((err)=>{
      return err?.response;
    })
};

export const verifyPrescreeningAccess = async (token) => {
  try {
      const result = await axios.get('/pre_screening/verify_client_access', {
          headers: {
              'X-Client-Request-Access-Token': token,
              'Authorization': null
          }
      });
      return result.data;
  } catch (err){
      throw err;
  }
};

export const candidateFeedbackAPI = (payload,jwtToken) => {
  return axios.post(`/candidate_pre_screening_round/submit_candidate_feedback`,payload,{
    headers:{
      'Authorization': null,
      'X-Candidate-Request-Access-Token': jwtToken,
    }
  })
  .then(res=>{
    return res;
  }).catch(error=>{
    throw error?.response;
  })
}

/* Skill Section */
export const getAllSkillsAPI = (payload) => {
  return axios.post(`/quickscreen/skill/all`,payload).then(res=>{
      return res;
  }).catch(error=>{
      throw error?.response || error;
    })
}

export const getUserIpAddress = () => {
  return Axios.get(`https://api64.ipify.org`,{
    headers:{ 
      "Content-Type": "application/json",
    }
  })
  .then((response) => {
    return response;
  })
  .catch((error) => {
    return error?.response || error;
  });
};

export const getUserLocationFromIp = (ipAddress) => {
   return Axios.get(`https://ipinfo.io/${ipAddress}?token=31e49ffb020f53`,{
    headers:{ 
      "Content-Type": "application/json",
    }
  })
  .then((response) => {
    return response;
  })
  .catch((error) => {
    return error?.response || error;
  });
};

