import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateClientList } from "../store/actions/Clients";
import { updateSelectedClient } from "../store/actions/SelectedClient";
import { updateClientImage } from "../store/actions/ClientImages";
import { loadImage } from "../Utils/image";
import { getClientListForUserRole } from "./clients";
import moment from "moment-timezone";

const ClientProvider = ({ children }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const isAuthenticated = !!user.id;
  const clients = useSelector((state) => state.clients);
  const selectedClient = useSelector((state) => state.selected_client);
  const clientImages = useSelector((state) => state.client_images);
  console.log("user>>>>", user);
  useEffect(() => {
    if (isAuthenticated) {
      getClientListForUserRole(user?.roles)
        .then((clientResult) => {
          console.log("clientResult", clientResult);
          dispatch(updateClientList(clientResult));
        })
        .catch(console.error);
    }
  }, [dispatch, isAuthenticated, user]);

  useEffect(() => {
    if (clients?.length === 1) {
      dispatch(updateSelectedClient(clients[0]));
    }
  }, [dispatch, clients]);

  useEffect(() => {
    if (clients.length > 0) {
      clients?.forEach((client) => {
        if (!clientImages[client?.id]) {
          loadImage(client?.account_info?.logoUrl, (err, result) => {
            if (result) {
              dispatch(updateClientImage(client.id, result));
            }
          });
        }
      });
    }
  }, [dispatch, clients]);

  useEffect(() => {
    if (user?.user_config?.timezone) {
      moment.tz.setDefault(user?.user_config?.timezone);
    }
  }, [user]);

  const isClientSelected = selectedClient?.id;

  return (
    <>{React.cloneElement(children, { isAuthenticated, isClientSelected })}</>
  );
};

export default ClientProvider;
