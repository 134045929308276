import { Redirect, Route } from "react-router-dom";

const PublicRoute = ({ component: Component, redirect, ...children }) => {
  if(redirect && redirect?.length>0)
  {
    return <Redirect from={children.path} to={redirect+children.location.search}/>
  }

  return (
    <Route
    {...children}
      render={
        (props) =>
          <Component {...props} {...children} />
      }
    />
  )
};

export default PublicRoute;
