import React from "react";
import zipy from 'zipyai';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/es/integration/react'
import configureStore from "./store";
import ClientProvider from "./services/ClientProvider";
import AppRouter from "./routes";
import GoogleAnalytics from "./services/GoogleAnalytics";
import Toasts from "./Utils/toasts";

const { persistor, store } = configureStore();

const App = () => {
  if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_STAGE === "prod") {
    zipy.init('7e61bd4d');
  }

  return (
    <GoogleAnalytics>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
            <div className="App">
              <ClientProvider>
                <AppRouter />
              </ClientProvider>
            </div>
            <Toasts/>
        </PersistGate>
      </Provider>
    </GoogleAnalytics>
  );
};

export default App;
