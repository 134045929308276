import axios from "./axios";

export const integrationApi = async (payload, jwtToken) => {
  return axios
    .post(`/lever/login`, payload, {
      headers: {
        "X-Candidate-Request-Access-Token": jwtToken,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const leverReAuthenticationApi = async (payload, jwtToken) => {
  return axios
    .post(`/lever/re-authenticate`, payload, {
      headers: {
        "X-Candidate-Request-Access-Token": jwtToken,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const slackIntegrationApi = async (payload, jwtToken) => {
  return axios
    .post(`/slack/login`, payload, {
      headers: {
        "X-Candidate-Request-Access-Token": jwtToken,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const gmailIntegrationApi = async (client_id) => {
  return axios
    .get(`/client/${client_id}/gmail`)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err?.response;
    });
};
    
export const outlookIntegrationApi = async (client_id) => {
  return axios.get(`/client/${client_id}/outlook`)
  .then(response=>{
    return response;
  })
  .catch(error=>{
    return error?.response;
  })
};

export const whatsappIntegrationApi = async (payload) => {
  return axios.post(`/whatsapp/client/configure`, payload)
  .then((response) => {
    return response;
  })
  .catch((err) => {
    return err.response;
  });
}

export const integrationStatusApi = async (payload, jwtToken) => {
  return axios
    .post(`/integrations/status`, payload, {
      headers: {
        "X-Candidate-Request-Access-Token": jwtToken,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const integrationRefreshApi = async (payload, jwtToken) => {
  return axios
    .post(`/interview/refresh_lever_jobs`, payload, {
      headers: {
        "X-Candidate-Request-Access-Token": jwtToken,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const integrationJobsApi = async (payload, jwtToken) => {
  return axios
    .post(`/position/all`, payload, {
      headers: {
        "X-Candidate-Request-Access-Token": jwtToken,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const integrationUpdateStatus = async (payload, jwtToken) => {
  return axios
    .post(`/lever/update_status`, payload, {
      headers: {
        "X-Candidate-Request-Access-Token": jwtToken,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const greenHouseStatusUpdate = async (status, id) => {
  return axios
    .post(`greenhouse/client/${id}/update_status`, status)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const workableStatusUpdate = async (status, id) => {
  return axios
    .post(`workable/client/${id}/update_status`, status)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const whatsappNotificationStatus = async (client_id) => {
  return axios
    .get(`whatsapp/client/${client_id}/status`)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err?.response;
    });
};

export const activatePosition = async (payload, jwtToken) => {
  let positionPayload = payload?.position_id;
  let clientId ={ client_id: payload?.client_id};
  return axios
    .post(`/position/${positionPayload}/activate`,clientId , {
      headers: {
        "X-Candidate-Request-Access-Token": jwtToken,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const handleLeverOpportunity = async (headers)=>{
  return axios.post(`/retry-lever-opportunity`, {},{headers:headers}).then((response)=>{
    return response;
  }).catch((error)=>{
    return error;
  })
}

export const handleStageChange = async (integration , headers)=>{
  return axios.post(`/retry-${integration}-stage-change`, {},{headers:headers}).then((response)=>{
    return response;
  }).catch((error)=>{
    return error;
  })
}

// Interview Created API
export const interviewCreatedApi = async (headers)=>{
  return axios.post(`/lever/next-round-interview`, {},{headers:headers}).then((response)=>{
    return response;
  }).catch((error)=>{
    return error;
  })
}

export const interviewCreatedApiGreenHouse = async (headers)=>{
  return axios.post(`/greenhouse/next-round-interview`, {},{headers:headers}).then((response)=>{
    return response;
  }).catch((error)=>{
    return error;
  })
}

export const interviewCreatedApiWorkable = async (headers)=>{
  return axios.post(`/workable/next-round-interview`, {},{headers:headers}).then((response)=>{
    return response;
  }).catch((error)=>{
    return error;
  })
}

// Green House APi

export const verifyApiKey = async (payload, jwtToken) => {
  return axios
    .post(`greenhouse/client/${payload?.client_id}/api_key`, payload, {
      headers: {
        "X-Candidate-Request-Access-Token": jwtToken,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};


export const triggerWebHook = async (id) => {
  return axios
    .get(`greenhouse/client/${id}/webhook_info`)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};


export const verifyWebHook = async (id) => {
  return axios
    .get(`greenhouse/client/${id}/verify_webhook`)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const refreshJobsGreenHouse = async (id) => {
  return axios
    .post(`greenhouse/client/${id}/refresh_jobs`, {})
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const verifyAccessToken = async (payload, jwtToken) => {
  return axios
    .post(`workable/client/${payload?.client_id}/api_key`, payload, {
      headers: {
        "X-Candidate-Request-Access-Token": jwtToken,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const refreshJobsWorkable = async (id) => {
  return axios
    .post(`workable/client/${id}/refresh_jobs`, {})
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const revokeIntegrationAccess = async (integrationName, payload) => {
  return axios.post(`/${integrationName}/revoke`, payload)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getSlackChannelsAPI = (client_id)=>{
  return axios.get(`/slack/get_channels/${client_id}`)
  .then(response=>{
    return response;
  })
  .catch(error=>{
    return error?.response;
  })
}

export const getSlackClientConfigAPI = (client_id)=>{
  return axios.get(`/client/notification_config/slack/${client_id}`)
  .then(response=>{
    return response;
  })
  .catch(error=>{
    return error?.response;
  })
}
export const createChannelAPI = (client_id,channelName) =>{
  return axios.post(`/slack/create_channel`,{
    client_id: client_id,
    channel_name: channelName,
  })
  .then(response=>{
    return response;
  })
  .catch(error=>{
    return error?.response;
  })
}

export const saveNotificationConfigAPI = (client_id,payload)=>{
  return axios.patch(`/client/notification_config/slack/${client_id}`,payload)
  .then(response=>{
      return response;
  })
  .catch(error=>{
      return error?.response;
  })
}